<div class={`root ${$$props.class}`}>
    <slot />
</div>

<style>
    .root {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 1px 6px;

        font-size: 10px;
        font-weight: 600;
        line-height: 100%;

        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.29);
        background: linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%);
    }
</style>