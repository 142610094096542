import WebApp from "@twa-dev/sdk";
import { effect } from "../components/Effect.svelte";
import { format } from "../uikit/Balance.svelte";
import { notify } from "../components/Notifications.svelte";

export const buildEffect = {
  reward(count: number) {
    notify.emit(
      `Reward: ${format(count)} RXP`,
      "reward",
    );
    effect.emit();
    WebApp.HapticFeedback.notificationOccurred("success");
  },
  error(error: any) {
    var message = error.message;
    try {
      message = JSON.parse(message)?.message ?? error.message;
    } catch (e) { }
    notify.emit(`Error: ${message}`);
    WebApp.HapticFeedback.notificationOccurred("error");
    console.error(message);
  }
};