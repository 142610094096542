import {skin1, skin10, skin2, skin3, skin4, skin5, skin6, skin7, skin8, skin9} from "./images/skins";

export const SHOW_FRIENDS_LIMIT = 5;
export const TIMEZONE = new Date().getTimezoneOffset();

export const TEST_USERNAMES = ['bulhik', 'George_Gus', 'GamegaOleg', 'kateconst', 'uxname']

export const SKINS = {
    1: {
        boost: 1,
        lvl: 1,
        skin: skin1,
    },
    2: {
        boost: 1.1,
        lvl: 2,
        skin: skin2,
    },
    3: {
        boost: 1.2,
        lvl: 3,
        skin: skin3,
    },
    4: {
        boost: 1.5,
        lvl: 4,
        skin: skin4,
    },
    5: {
        boost: 1.7,
        lvl: 5,
        skin: skin5,
    },
    6: {
        boost: 1.8,
        lvl: 6,
        skin: skin6,
    },
    7: {
        boost: 2,
        lvl: 7,
        skin: skin7,
    },
    8: {
        boost: 3,
        lvl: 15,
        skin: skin8,
    },
    9: {
        boost: 4,
        lvl: 17,
        skin: skin9,
    },
    10: {
        boost: 5,
        lvl: 20,
        skin: skin10,
    },
}