<script lang="ts">
    import coins from '../assets/coins.png'
    import WebApp from "@twa-dev/sdk";
</script>

<div>
    <img src={coins} alt="" />
    <div class="description">
        Buy Whitelist for<br> Guarant <span>$RAX</span> Pre-Sale
    </div>

    <div class="footer">
        <div class="action">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.28451 5.00065H9.95117V3.33398H8.28451V5.00065ZM11.6178 5.00065V3.33398H13.2845V5.00065H11.6178ZM8.28451 11.6673V10.0007H9.95117V11.6673H8.28451ZM14.9512 8.33398V6.66732H16.6178V8.33398H14.9512ZM14.9512 11.6673V10.0007H16.6178V11.6673H14.9512ZM11.6178 11.6673V10.0007H13.2845V11.6673H11.6178ZM14.9512 5.00065V3.33398H16.6178V5.00065H14.9512ZM9.95117 6.66732V5.00065H11.6178V6.66732H9.95117ZM4.95117 16.6673V3.33398H6.61784V5.00065H8.28451V6.66732H6.61784V8.33398H8.28451V10.0007H6.61784V16.6673H4.95117ZM13.2845 10.0007V8.33398H14.9512V10.0007H13.2845ZM9.95117 10.0007V8.33398H11.6178V10.0007H9.95117ZM8.28451 8.33398V6.66732H9.95117V8.33398H8.28451ZM11.6178 8.33398V6.66732H13.2845V8.33398H11.6178ZM13.2845 6.66732V5.00065H14.9512V6.66732H13.2845Z" fill="url(#paint0_linear_8899_28549)"/>
                <defs>
                    <linearGradient id="paint0_linear_8899_28549" x1="7.77809" y1="3.33399" x2="18.9484" y2="8.67441" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#587BFF"/>
                        <stop offset="1" stop-color="#B518FF"/>
                    </linearGradient>
                </defs>
            </svg>
            Roadmap
        </div>
        <div class="action" on:click={() => WebApp.openLink('https://app.ray.sx/assets/documets/RaysX_PitchDeck.pdf')}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.61784 16.6673C3.15951 16.6673 2.76714 16.5041 2.44076 16.1777C2.11437 15.8513 1.95117 15.459 1.95117 15.0007V5.00065C1.95117 4.54232 2.11437 4.14996 2.44076 3.82357C2.76714 3.49718 3.15951 3.33398 3.61784 3.33398H16.9512C17.4095 3.33398 17.8019 3.49718 18.1283 3.82357C18.4546 4.14996 18.6178 4.54232 18.6178 5.00065V15.0007C18.6178 15.459 18.4546 15.8513 18.1283 16.1777C17.8019 16.5041 17.4095 16.6673 16.9512 16.6673H3.61784ZM5.597 15.0007H16.9512V5.00065H13.347L15.2845 12.5007L5.597 15.0007Z" fill="url(#paint0_linear_8899_17686)"/>
                <defs>
                    <linearGradient id="paint0_linear_8899_17686" x1="5.98963" y1="3.33399" x2="19.3585" y2="12.4647" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#587BFF"/>
                        <stop offset="1" stop-color="#B518FF"/>
                    </linearGradient>
                </defs>
            </svg>
            Pitchdeck
        </div>
    </div>
</div>

<style lang="scss">
    .description {
      color: #FFF;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 110%;
      transform: translateY(-12px);

      & > span {
        background: linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
      }
    }

    .footer {
      display: flex;
      gap: 8px;
      width: 100%;

      & > .action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        background: #1A1B22;
        backdrop-filter: blur(1.5px);
        padding: 6px 12px;
        width: 100%;
        color: #AEB0B7;
        font-size: 13px;
        font-weight: 600;
        line-height: 120%;
      }
    }
</style>