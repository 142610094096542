import { client } from "../library/fetch";
import { delay } from "../library/delay";
import { signal } from "@preact/signals-core";

export const miningData = client.api.game.mining.get().then(({ data }) => data);
export const mining = signal<PromiseResult<typeof miningData> & { local: number; }>({
  current: null,
  time: Date.now(),
  local: Date.now()
});

miningData.then(data => data && mining.set({
  ...data,
  local: Date.now()
}));

export const updateMining = () => {
  return client.api.game.mining.get()
    .then(({ data }) => data && mining.set({ ...data, local: Date.now() }));
};

async function loop() {
  while (true) {
    await updateMining()
      .catch(console.error);
    await delay(5_000);
  }
}

loop();