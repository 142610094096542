import { beforeUpdate, onDestroy, onMount } from "svelte";

export const onEffect = (effect: () => (() => void) | void) => {
  let dispose: (() => void) | void = undefined;

  onMount(() => {
    dispose = effect();
  });

  beforeUpdate(() => {
    dispose?.();
    dispose = effect();
  });

  onDestroy(() => {
    dispose?.();
  });
};