<script lang="ts">
    import Flex from "../../../components/Flex.svelte";
    import GifGem from "../../../uikit/GifGem.svelte";
    import Balance from "../../../uikit/Balance.svelte";
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher();

    export let variant: 'line' | 'block'
    export let icon: string
    export let name: string
    export let lvl = 0
    export let price = 0
    export let boost = 0
    export let needLvl: false | number
    export let id

    $: isAvailable = needLvl === false

    $: isActive = lvl > 0

    const handlerOpen = () => {
        if (isAvailable) {
            dispatch('open', id)
        }
    }
</script>

<div
        class={`root ${variant} ${!isActive && isAvailable && 'not_active'} ${!isAvailable && 'disabled'}`}
        on:mousedown={handlerOpen}
        role="button"
        tabindex={1}
>
    <img src={icon} alt="" />

    <div class="info">
        <div class="description">
            <span class="title">{name}</span>
            <Flex gap={4}>
                <GifGem up size={20} />
                <span class="boost"><Balance balance={boost} big symbol disableFrame f={1} /></span>
                <span class="time">1h Mining</span>
            </Flex>
        </div>

        <div class={`actions ${isActive && 'active'}`}>
            <div class="lvl">
                {#if (!isAvailable)}
                    <span>Need LvL {needLvl}</span>
                {:else}
                    <span>LvL {lvl}</span>
                {/if}
            </div>

            {#if (isAvailable)}
                <div class="price">
                    <Balance balance={price} big disableFrame />
                    <GifGem size={12} />
                </div>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .root {
      border-radius: 20px;
      border: 1px solid #313238;

      &.disabled {
        filter: grayscale(1);
      }

      &.not_active:before {
        z-index: 1;
        content: "";
        position: absolute;
        inset: 0;
        background: black;
        opacity: 0.5;
      }
    }

    .line {
      padding: 16px;
      position: relative;
      display: flex;
      gap: 10px;
      background: linear-gradient(90deg, #24252D 0%, #1A1B22 78%);
      border: none;

      & > img {
        min-width: 50px;
        height: 50px;
        margin-right: 10px;
      }

      & .info {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      & .description {
        display: flex;
        gap: 4px;
        flex-direction: column;
        align-items: start;
      }

      & .actions {
        display: flex;
        justify-content: end;
        align-items: end;

        & > .lvl {

        }

        & > .price {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
    }

    .block {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: hidden;
      box-sizing: revert;
      z-index: 1;

      & > img {
        width: 100%;
        height: calc(50vw - 32px);
        object-fit: cover;
        z-index: -1;
      }

      & > .info {
        padding: 16px 12px;
        margin-top: -36px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, rgba(20, 21, 27, 0.00) 0%, rgba(20, 21, 27, 0.49) 8.5%, rgba(20, 21, 27, 0.89) 20.5%, #14151B 100%);
      }

      & .actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        & > .lvl {
          border-radius: 30px;
          background: #24252D;
          backdrop-filter: blur(16px);
          padding: 4px 8px;
        }
      }

      & .description {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap:4px;
        margin-bottom: 8px;
        align-items: start;
      }
    }

    .title {
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
      text-align: start;
    }

      .boost {
        margin-left: 4px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
      }

      .time {
        color: #878892;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
      }

      .actions {
        color: #878892;
        font-size: 12px;
        font-weight: 600;
        line-height: 140%;

        &.active {
          color: var(--Text-White, #FFF);
        }

        & > .price {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 4px 7px;
          border-radius: 30px;
          background: #1D59F6;
          backdrop-filter: blur(16px);
          color: #FFF;
          font-size: 12px;
          font-weight: 500;
          line-height: 140%;
        }
      }

      .lvl {
        white-space: nowrap;
      }
</style>