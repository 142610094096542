<script lang="ts">

    import Carousel from "../../../uikit/Carousel.svelte";
    import {SKINS} from "../../../config";
    import Boost from "../../../uikit/Boost.svelte";
    import {tabActivate} from '../../../components/Tabs/TabsItem.svelte'
    import Icon from "../../../components/Icon.svelte";
    import {profile} from "../../../store/profile";

    const skins = Object.values(SKINS)

    let currentSkin = $profile!.level.currentUserSkinIndex + 1 > 10 ? 10 : $profile!.level.currentUserSkinIndex + 1 as keyof typeof SKINS;
</script>

<div class="carousel">
    <Carousel
            loop={false}
            dots={false}
            perPage={1}
            on:change={({ detail }) => currentSkin = detail.currentSlide + 1}
            startIndex={currentSkin - 1}
    >
        {#each skins as skin, index (index)}
            <img src={skin.skin} height="250px" alt={`skin_${skin.lvl}`}/>
        {/each}
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
             slot="left-control" class="arrow">
            <path d="M9.23682 14.0467L18.4681 23.2666C18.631 23.4334 18.7159 23.6447 18.7227 23.9004C18.7295 24.1561 18.6428 24.3714 18.4625 24.5464C18.2936 24.729 18.0787 24.8203 17.8179 24.8203C17.5574 24.8203 17.3358 24.729 17.1532 24.5464L7.77178 15.1796C7.6065 15.0219 7.48925 14.8461 7.42003 14.6522C7.351 14.4586 7.31649 14.2567 7.31649 14.0467C7.31649 13.8289 7.351 13.6232 7.42003 13.4296C7.48926 13.2359 7.6065 13.0601 7.77178 12.9022L17.1567 3.51756C17.3391 3.33498 17.5601 3.24767 17.8197 3.25565C18.0793 3.26342 18.2936 3.35851 18.4625 3.5409C18.6428 3.70248 18.7334 3.91063 18.7344 4.16535C18.7355 4.41988 18.6449 4.63844 18.4625 4.82102L9.23682 14.0467Z"
                  fill="#62646E"/>
        </svg>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
             slot="right-control" class="arrow">
            <path d="M18.7632 13.9533L9.53193 4.7334C9.36898 4.56656 9.28411 4.3553 9.2773 4.0996C9.2705 3.84391 9.35722 3.62856 9.53747 3.45356C9.70644 3.27098 9.9213 3.17969 10.1821 3.17969C10.4426 3.17969 10.6642 3.27098 10.8468 3.45356L20.2282 12.8204C20.3935 12.9781 20.5107 13.1539 20.58 13.3478C20.649 13.5414 20.6835 13.7433 20.6835 13.9533C20.6835 14.171 20.649 14.3768 20.58 14.5704C20.5107 14.7641 20.3935 14.9399 20.2282 15.0978L10.8433 24.4824C10.6609 24.665 10.4399 24.7523 10.1803 24.7444C9.92072 24.7366 9.70644 24.6415 9.53747 24.4591C9.35722 24.2975 9.26661 24.0894 9.26564 23.8346C9.26447 23.5801 9.35508 23.3616 9.53747 23.179L18.7632 13.9533Z"
                  fill="#62646E"/>
        </svg>
    </Carousel>

    <div class="info">
        <Boost value={SKINS[currentSkin].boost}/>
        <div class="about">
            <div class="title">
                {currentSkin} Skin
            </div>
            <div class="description">
                Mining Boost
            </div>
        </div>
    </div>
</div>

<button class="primary upgrade" on:mousedown={() => tabActivate.emit("skins")}>
    <Icon icon="tgStars" size={20}/>
    Upgrade My Skin
</button>

<style>
    .carousel {
        position: relative;
    }

    .arrow {
        width: 28px;
        height: 28px;
    }

    .info {
        padding: 8px 16px;
        width: fit-content;
        display: flex;
        gap: 8px;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        right: 0;
        left: 0;
        border-radius: 20px;
        border: 1px solid #313238;
        background: linear-gradient(90deg, #24252D 0%, #1A1B22 78%);
    }

    .about {
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
    }

    .description {
        color: #AEB0B7;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
    }

    .upgrade {
        top: 0;
        position: sticky;
        min-height: 48px;
    }
</style>