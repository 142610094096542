<script lang="ts">
  export let icon: string;
  export let color: string = "#fff";
  export let big = false;
</script>

<div class="s-icon {big ? 'big' : ''}" style="--color: {color}">
  <i class="icon-{icon}" />
</div>

<style lang="sass">
  .s-icon
    --color: #7D7D7D
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 34px
    height: 34px
    border: 1px solid var(--color)
    border-radius: 8px
    flex-shrink: 0
    box-shadow: 0px 0px 10px 0px var(--color)
    background: linear-gradient(148.93deg, var(--background) 27.08%, color-mix(in srgb, var(--color), #00000099) 103.79%)

    &::before
      content: ''
      display: block
      position: absolute
      bottom: 13%
      left: 20%
      right: 20%
      height: 15%
      background: color-mix(in srgb, var(--color), #fff)
      border-radius: 100%
      filter: blur(5px)

    i
      position: absolute
      font-size: 20px
      text-shadow: 0px 2px 6px var(--color)

    &.big
      width: 78px
      height: 78px
      border-radius: 16px
      box-shadow: 0px 0px 20px 0px var(--color)

      i
        font-size: 45px
        text-shadow: 0px 4px 6px var(--color)
</style>
