import { Signal, batch, computed, effect, signal } from "@preact/signals-core";

declare module "@preact/signals-core" {
  interface Signal<T> {
    update(fn: (v: T) => T): void;
    set(v: T): void;
  }
}

Signal.prototype.update = function (fn) {
  this.value = fn(this.value);
};

Signal.prototype.set = function (v) {
  this.value = v;
};