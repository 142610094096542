<script lang="ts" context="module">
  const flexAlign = {
    start: "flex-start",
    end: "flex-end",
    center: "center",
  } as const;

  const flexJustify = {
    ...flexAlign,
    between: "space-between",
    around: "space-around",
    evenly: "space-evenly",
    stretch: "stretch",
  } as const;
</script>

<script lang="ts">
  export let column = false;
  export let reverse = false;
  export let gap: number | null = null;
  export let justify: keyof typeof flexJustify | null = null;
  export let align: keyof typeof flexAlign | null = null;
  export let full = false;
  export let fullwidth = false;
  export let grow = false;
  export let className = "";
  export let padding: string | null = null;

  $: direction = (column ? "column" : "row") + (reverse ? "-reverse" : "");
  $: flexGap = gap ? `${gap}px` : "none";
  $: flexGrow = grow ? "1" : "none";
  $: justifyContent = justify ? flexJustify[justify] : "none";
  $: alignItems = align ? flexAlign[align] : "none";
  $: size = full ? "100%" : "auto";
  $: flexPadding = padding ?? "0px";
</script>

<div
  class="flex {className} {$$props.class}"
  style="
    flex-direction: {direction};
    justify-content: {justifyContent};
    align-items: {alignItems};
    flex-grow: {flexGrow};
    gap: {flexGap};
    width: {full || fullwidth ? '100%' : 'auto'};
    height: {size};
    padding: {flexPadding};
  "
>
  <slot />
</div>

<style lang="sass">
  .flex
    display: flex
</style>
