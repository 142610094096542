import { onDestroy, onMount } from "svelte";

type TLoopListener = (dtime: number, time: number) => any;

var loops = new Set<TLoopListener>();
var lastTime = performance.now();
var delaTime = 0;

function loop(time: number) {
  requestAnimationFrame(loop);

  delaTime = time - lastTime;
  lastTime = time;

  for (const loop of [...loops])
    loop(delaTime, lastTime);
}

requestAnimationFrame(loop);

export const runLoop = <T extends TLoopListener>(loop: T): ReturnType<T> => {
  return loop(delaTime, lastTime);
};

export const onFrame = (loop: TLoopListener) => {
  runLoop(loop);

  onMount(() => {
    loops.add(loop);
  });

  onDestroy(() => {
    loops.delete(loop);
  });
};