<script lang="ts">
  import WebApp from "@twa-dev/sdk";
  import Flex from "./Flex.svelte";
  import { notify } from "./Notifications.svelte";
  import { profile } from "../store/profile";

  $: inviteLink = $profile!.inviteLink;
</script>

<div class="invite">
  <Flex gap={8} fullwidth>
    <button
      class="primary"
      on:click={() => {
        WebApp.HapticFeedback.notificationOccurred("success");
        window.open(`https://t.me/share/url?url=${inviteLink}`);
      }}
    >
      Invite a Friend
    </button>

    <button
      style="width: 60px"
      class="primary"
      on:mousedown={() => {
        WebApp.HapticFeedback.notificationOccurred("success");
        notify.emit("You invite link copy in clipboard", "copy");
        navigator.clipboard.writeText(inviteLink);
      }}
    >
      <!--  -->
      <i class="icon-copy" />
    </button>
  </Flex>
</div>

<style lang="sass">
  .invite
    width: 100%
    position: sticky
    bottom: 0px
    padding: 16px
</style>
