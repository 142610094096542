<script lang="ts">
  import Flex from "./Flex.svelte";
  import { profile } from "../store/profile";
  import Balance from "../uikit/Balance.svelte";
  import { tabActivate } from "./Tabs/TabsItem.svelte";
  import {SKINS} from "../config";
  import GifGem from "../uikit/GifGem.svelte";
  import * as levelsImages from "../images/avatars/dragons";
  import {levels} from "../store/levels";

  export var withMargin: boolean = false
  export var disablePadding: boolean = false

  $: balance = Number($profile!.balance);
  $: mining = Number($profile!.mining.hourReward);
  $: skin = Number($profile!.mining.skinReward);
  $: level = $profile!.level.currentUserLevelIndex + 1;
  $: avatar = `lvl${level}` as keyof typeof levelsImages
  $: skinCoefficient = SKINS[($profile!.level.currentUserSkinIndex + 1) as keyof typeof SKINS]?.boost || 0;
  $: needNextLevel = $profile!.level.nextUserLevel
    ? $profile!.level.nextUserLevel.from - $profile!.level.currentUserLevel.from
    : 0;
  $: remainingNextLevel = $profile!.level.nextUserLevel
    ? $profile!.level.nextUserLevel.from - $profile!.experience
    : 0;
  $: persent = $levels?.at(-1)?.id === level ? 100 :
    (needNextLevel ? 1 - remainingNextLevel / needNextLevel : 0) * 100;
</script>

<div style="margin-bottom: {withMargin ? 16 : 0}px; padding-inline: {disablePadding ? 0 : 16}px;}">
  <Flex fullwidth justify="between" align="center">
    <div on:click={() => tabActivate.emit("profile")} class="avatar">
      <img src={levelsImages[avatar]} alt="" />
      <div class="block">
        <div>{level}LvL</div>
        <div class="progress">
          <div class="bar" style="width: {persent}%;" />
        </div>
      </div>
    </div>

    <div class="container">
        <div class="title">Mining 1h</div>

        <div class="title skin">
          Skin 1h
          <div class="badge">
            x{skinCoefficient}
            <Flex column>
              <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.83464 12.9997L4.5013 10.5573L0.167969 12.9997L4.5013 6.06641L8.83464 12.9997Z" fill="white" fill-opacity="0.63"/>
                <path d="M8.83464 6.93333L4.5013 4.49091L0.167969 6.93333L4.5013 0L8.83464 6.93333Z" fill="white" fill-opacity="0.88"/>
              </svg>
            </Flex>
          </div>
        </div>
      <div class="title"> Balance</div>
      

      <Flex gap={8} align="center">
        <GifGem up size={16} />
        <Balance balance={mining} big symbol disableFrame f={1} />
      </Flex>

        <Flex gap={8} align="center">
          <GifGem up size={16} />
          <Balance balance={skin} big symbol disableFrame f={1} />
        </Flex>

        <Flex gap={4} align="center">
          <GifGem size={16} />
          <Balance {balance} big disableFrame f={1} />
        </Flex>
    </div>
  </Flex>
</div>

<style lang="scss">
  .container {
    margin-left: 12px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 1.5px;
    align-items: center;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 120% !important;
  }

  .avatar {
    min-width: 52px;
    height: 52px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    & > img {
      min-width: 52px;
      min-height: 52px;
    }

    & > .block {
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      color: #FFF;
      text-shadow: 0 1px 4.6px #000;
      font-size: 10px;
      font-weight: 600;
      line-height: 120%;
      padding: 1px;

      & > div {
        text-align: start;
      }

      & > .progress {
        background-color: #24252D;
        height: 8px;
        width: 100%;
        border-radius: 20px;
        padding: 1px;

        & > .bar {
          height: 100%;
          border-radius: 20px;
          background: linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%);
        }
      }
    }
  }

  img {
    width: 52px;
    height: 52px;
  }

  .title {
    text-align: start;
    color: var(--Text-Gray-Light, #AEB0B7);
    font-size: 11px;
    font-weight: 600;
    line-height: 120%;
  }

  .skin {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    //justify-content: space-between;
  }

  .badge {
    height: 20px;
    max-width: 44px;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-shadow: 0 1px 4.6px #000;
    font-size: 10px;
    line-height: 100%;
    font-weight: 600;
    display: flex;
    gap: 2px;
    border-radius: 8px;
    background: linear-gradient(124deg, #FF8425 4.02%, #C33FE6 34.64%, #7000C5 74.08%);
    box-shadow: 0 0 12.429px 1.714px rgba(158, 48, 255, 0.83);
  }

  .last {
    transform: translateY(-1px);
  }
</style>
