<script lang="ts">
  import Banner from "../../components/Banner.svelte";
  import Flex from "../../components/Flex.svelte";
  import Header from "../../components/Header.svelte";
  import { updateMining } from "../../store/mining";
  import { updateProfile } from "../../store/profile";
  import HeaderNew from "../../components/HeaderNew.svelte";
  import Game from "./components/Game.svelte";
  import StatInfo from "./components/StatInfo.svelte";
  import Banners from "./components/Banners.svelte";

  updateMining();
  updateProfile();
</script>

<div class="root">
  <HeaderNew />
  <!--  <Header />-->
  <Game />
  <!--  <StatInfo />-->
  <Banners />
</div>

<style lang="scss">
  .root {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }
</style>