export const events = <A extends any[] = []>() => {
  const subscribers = new Set<(...args: A) => any>();

  return {
    sub(listener: (...args: A) => any) {
      subscribers.add(listener);
      return () => {
        subscribers.delete(listener);
      };
    },
    emit(...args: A) {
      for (const sub of [...subscribers]) {
        sub(...args);
      }
    }
  };
};