<script lang="ts">
    import {SKINS} from "../../../config";
    import Boost from "../../../uikit/Boost.svelte";
    import Modal from "../../../components/Modal.svelte";
    import Flex from "../../../components/Flex.svelte";
    import Icon from "../../../components/Icon.svelte";
    import bgModal from '../images/bg-card-modal.png';
    import bgCard from '../images/bg-card.png';
    import GifGem from "../../../uikit/GifGem.svelte";
    import type {UserSkin} from "../../../../../backend/src/class/UserSkin";
    import type {SkinData} from "../Skins.svelte";
    import {profile} from "../../../store/profile";

    export let skinData: SkinData;
    export let skin: UserSkin;
    export let onBuy: () => Promise<void>;

    const isPurchased = $profile.level.currentUserSkinIndex + 1 >= skin.id

    let open = false;
    const skinId = skin.id as keyof typeof SKINS;

    const handlerModal = (state: boolean) => {
        open = state
    }

    async function buySkin() {
        await onBuy();
        open = false;
    }
</script>

<div class="root">
    <div style={`background-image: url(${bgCard})`} class="bg"/>
    <div class="photo">
        <Boost value={SKINS[skinId].boost}/>
        <img src={SKINS[skinId].skin} alt=""/>
    </div>
    <div class="block">
        <div class="head">
            <h3>{skinId} Skin</h3>
            <h3 class="lvl">{SKINS[skinId].lvl} lvl</h3>
            <GifGem up size={24}/>
        </div>
        <div class="description">
            {skinData.description}
        </div>
        {#if (!isPurchased)}
            <button
                    class="primary"
                    on:click={() => handlerModal(true)}
            >
                <Icon icon="tgStars" size={18}/>
                {skin.price}
            </button>
        {:else}
            <button class="purchased">
                👌 Purchased
            </button>
        {/if}
    </div>
</div>

{#if open}
    <Modal
            on:close={() => handlerModal(false)}
            zIndex={10}
            styleModal={`background-image: url(${bgModal}); background-repeat: no-repeat; background-size: cover;`}
    >
        <Flex column fullwidth align="center" gap={12}>
            <img src={SKINS[skinId].skin} width="100%" alt=""/>

            <div class="head">
                <h3>{skin.id} Skin</h3>
                <h3 class="lvl">{SKINS[skinId].lvl} lvl</h3>
            </div>

            <div class="description">
                {skinData.description}
            </div>

            <Flex gap={16}>
                <GifGem up size={32}/>
                <Boost value={SKINS[skinId].boost}/>
            </Flex>

            <button class="primary" on:click={buySkin}>
                <Icon icon="tgStars" size={18}/>
                {skin.price}
            </button>
        </Flex>
    </Modal>
{/if}

<style>
    .root {
        border-radius: 20px;
        border: 1px solid #313238;
        background: linear-gradient(90deg, #24252D 0%, #1A1B22 78%);
        display: flex;
        gap: 8px;
        position: relative;

        & > .bg {
            background-size: cover;
            position: absolute;
            background-repeat: no-repeat;
            inset: 0;
            z-index: 0;
        }
    }

    .photo {
        position: relative;
        width: 100%;

        & > *:first-child {
            position: absolute;
            bottom: 16px;
            left: 16px;
        }

        & > img {
            height: 152px;
            width: 100%;
        }
    }

    .block {
        padding: 16px 0;
        padding-right: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > button {
            height: 40px;
            z-index: 1;
        }
    }

    .head {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 120%;
        white-space: nowrap;

        & > .lvl {
            color: #62646E;
        }
    }

    .description {
        text-align: start;
        color: #AEB0B7;
        font-size: 12px;
        font-weight: 600;
        line-height: 140%;
    }

    .purchased {
        color: #878892;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        border: none;
        border-radius: 32px;
        background: #313238;
        backdrop-filter: blur(1.5px);
    }
</style>