<script lang="ts">
  import WebApp from "@twa-dev/sdk";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { fade, slide } from "svelte/transition";
  import { click } from "../library/click";
  import { outside } from "../library/outside";

  export var zIndex = 0;
  export var styleModal = '';

  const dispatch = createEventDispatcher<{ close: void }>();

  var mounted = false;

  function close() {
    if (!mounted) return;
    dispatch("close");
  }

  onMount(() => {
    setTimeout(() => (mounted = true));
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(close);
    WebApp.HapticFeedback.impactOccurred("heavy");
  });

  onDestroy(() => {
    setTimeout(() => (mounted = false));
    WebApp.BackButton.hide();
    WebApp.BackButton.offClick(close);
    WebApp.HapticFeedback.impactOccurred("heavy");
  });
</script>

<div
  role="button"
  tabindex={-1}
  on:mousedown={close}
  class="container"
  in:fade
  out:fade={{ duration: 0 }}
  style={zIndex ? `z-index: ${zIndex}` : ''}
>
  <div
    class="modal"
    role="button"
    tabindex={-1}
    on:mousedown={(e) => e.stopPropagation()}
    in:slide
    out:slide={{ duration: 0 }}
    style={styleModal}
  >
    <div class="close" use:click={close}>
      <i class="icon-close" />
    </div>
    <div class="content" use:outside={close}>
      <slot {close} />
    </div>
  </div>
</div>

<style lang="sass">
  .container
    position: fixed
    inset: 0
    background-color: rgba(0,0,0,0.4)
    backdrop-filter: blur(5px)

    .modal
      position: absolute
      bottom: 0
      left: 0
      right: 0
      background-color: var(--container-dark)
      border-radius: 32px 32px 0 0

      .content
        padding: 22px 16px 50px 16px
        max-height: calc(100vh - 20px)
        overflow: auto

      .close
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        top: 22px
        right: 22px
        width: 30px
        height: 30px
</style>
