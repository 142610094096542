<script lang="ts">
    import Modal from "../../../components/Modal.svelte";
    import Flex from "../../../components/Flex.svelte";
    import GifGem from "../../../uikit/GifGem.svelte";
    import Balance from "../../../uikit/Balance.svelte";
    import {createEventDispatcher} from "svelte";
    import {profile, updateProfile} from "../../../store/profile";
    import {buyBoost, updateBoost} from "../../../store/boosts";
    import { notify } from "../../../components/Notifications.svelte";


    const dispatch = createEventDispatcher();

    const handlerClose = () => dispatch('close')

    export let variant: 'line' | 'block'
    export let icon: string
    export let name: string
    export let description: string
    export let price: number
    export let currentBoost: number
    export let nextBoost: number
    export let lvl: number
    export let id: number

    let promise: Promise<unknown> | null

    const handlerBuy = async () => {
        try {
            await buyBoost(id)
            await Promise.all([
                updateBoost(),
                updateProfile(),
            ])
            // .catch(buildEffect.error)
            notify.emit(
                `Successful pumping`,
                "reward",
            );
        } catch (e) {
            notify.emit(
                e.message,
            );
        }
        promise = null
        handlerClose()
    }

    $: balance = $profile.balance
    $: isSmallBalance = price > balance
</script>

    <Modal
            on:close={handlerClose}
            zIndex={10}
    >
        <Flex column fullwidth align="center" gap={12}>
            <img src={icon} width="100%" alt="" class="img {variant}"/>

            <h3 class="title">{name}</h3>
            <span class="description">{description}</span>

            <Flex gap={16} align="center">
                <GifGem up size={32} />
                <span class="balance">
                    <Balance balance={nextBoost - currentBoost} symbol big />
                </span>
                <div class="lvl">
                    LvL {lvl}
                </div>
            </Flex>

            <div class="progress">
                <span><Balance balance={currentBoost} big /> → </span>
                <span><Balance balance={nextBoost} big /></span>
            </div>

            {#if (isSmallBalance)}
                <div class="small_balance">
                    Insufficient Funds
                </div>
            {:else}
                <button
                        class="primary"
                        on:click={() => {
                            if (promise) return
                            promise = handlerBuy()
                        }}
                        disabled={!!promise}
                >
                    {#await promise}
                        <div class="loader" />
                    {:then _}
                        <GifGem size={18} />
                        <Balance balance={price} big />
                    {/await}
                </button>
            {/if}
        </Flex>
    </Modal>

<style>
    .loader {
        display: inline-flex;
        gap: 10px;
    }
    .loader:before,
    .loader:after {
        content: "";
        height: 20px;
        aspect-ratio: 1;
        border-radius: 50%;
        background:
                radial-gradient(farthest-side,#000 95%,#0000) 35% 35%/6px 6px no-repeat
                #fff;
        transform: scaleX(var(--s,1)) rotate(0deg);
        animation: l6 1s infinite linear;
    }
    .loader:after {
        --s: -1;
        animation-delay:-0.1s;
    }
    @keyframes l6 {
        100% {transform:scaleX(var(--s,1)) rotate(360deg);}
    }

    .img {
        height: 80px;
        width: 80px;

        &.block {
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.14);
            box-shadow: 0px 8.056px 8.378px 0px rgba(255, 255, 255, 0.27) inset;
        }
    }

    .title {
        color: #FFF;
        font-size: 22px;
        font-weight: 600;
        line-height: 120%;
    }

    .description {
        color: #AEB0B7;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
    }

    .balance {
        color: #6DFFA8;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
    }

    .lvl {
        border-radius: 30px;
        background: #24252D;
        backdrop-filter: blur(16px);
        padding: 4px 8px;
        color: #AEB0B7;
        font-size: 12px;
        font-weight: 600;
        line-height: 140%;
    }

    .progress {
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;

        & > span:first-child {
            color: #AEB0B7;
        }

        & > span:last-child {
            color: #FFF;
        }
    }

    .small_balance {
        color: var(--Base-White, #FFF);
        mix-blend-mode: luminosity;
        font-size: 17px;
        font-weight: 600;
        line-height: 120%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        padding: 8px 16px;
        align-self: stretch;
        border-radius: 16px;
        background: var(--Accent-Strong, #1D59F6);
    }
</style>