<script lang="ts" context="module">
  import { onEffect } from "../hooks/onEffect";
  import { events } from "../library/events";
  import { fly } from "svelte/transition";
  export const notify = events<[text: string, icon?: string]>();
  export type TNotify = {
    id: number;
    icon?: string;
    text: string;
  };
</script>

<script lang="ts">
  let id = 0;
  let notifyes = new Set<TNotify>();

  function notifyAdd(text: string, icon?: string) {
    const item = {
      id: id++,
      text,
      icon,
    };

    notifyes.add(item);
    notifyes = notifyes;

    setTimeout(() => {
      notifyes.delete(item);
      notifyes = notifyes;
    }, 2000);
  }

  onEffect(() => notify.sub(notifyAdd));
</script>

<slot />

<div class="notify">
  {#each [...notifyes] as notify (notify.id)}
    <div
      class="item"
      in:fly={{ y: 100, duration: 300 }}
      out:fly={{ y: -100, duration: 300 }}
    >
      {#if notify.icon}
        <i class="icon-{notify.icon}" />
      {/if}
      <p class="text">{notify.text}</p>
    </div>
  {/each}
</div>

<style lang="sass">
  .notify
    position: fixed
    inset: 0
    pointer-events: none
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 70px
    gap: 8px
    z-index: 10

    .item
      display: flex
      padding: 15px
      background-color: var(--container-light)
      border-radius: 12px
      border: 1px solid var(--container-light)
      gap: 14px

      i
        font-size: 20px
        color: var(--strong-green)

      .text
        font-family: Work Sans
        font-size: 14px
        font-weight: 500
        line-height: 16.8px
        text-align: center
        color: var(--white)

</style>
