import { client } from "../library/fetch";
import { delay } from "../library/delay";
import { signal } from "@preact/signals-core";

export const profileData = client.api.game.me.get().then(({ data }) => data);
export const profile = signal<PromiseResult<typeof profileData>>(null);
export const updateProfile = () => {
  return client.api.game.me.get()
    .then(({ data }) => (profile.set(data)));
};

profileData.then(data => profile.set(data));

async function loop() {
  while (true) {
    await updateProfile()
      .catch(console.error);
    await delay(5_000);
  }
}

loop();