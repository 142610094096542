<script lang="ts">
  import Flex from "../components/Flex.svelte";
  import Gif from "../components/Gif.svelte";
  import { tasks, updateTasks } from "../store/tasks";
  import { dailyEvents } from "../components/DailyReward.svelte";
  import { openTaskEvents } from "../components/OpenTask.svelte";
  import SolidIcon from "../components/SolidIcon.svelte";
  import { currentDaily, dailyList, updateListDaily } from "../store/daily";
  import Balance from "../uikit/Balance.svelte";
  import HeaderNew from "../components/HeaderNew.svelte";

  updateTasks();
  updateListDaily();
  $: available = $tasks?.filter((e) => !e.complate).length ?? 0;
  $: daily = $currentDaily;
</script>

<HeaderNew withMargin />
<Flex full column align="center" gap={20} padding="20px 16px 16px 16px">
  <Flex column gap={12} align="center">
    <Gif type="flying" size={72} />
    <h1>{available} Tasks Available</h1>
    <p>Сomplete tasks and earn <b>RXP</b> <Gif type="gem" size={14} /></p>
  </Flex>

  {#if daily}
    <Flex column align="start" fullwidth>
      <h3 class="left">Daily Tasks</h3>
      <Flex column fullwidth>
        <div
          class="row"
          role="button"
          tabindex="0"
          on:mousedown={() => dailyEvents.emit()}
        >
          <SolidIcon icon="calendar" color="#FF44D6" />
          <p class="title">Daily Reward</p>
          <span class="chip bgw">
            <span class="reward">
              <Balance balance={daily.reward} big symbol />
            </span>
            <Gif type="gem" size={15} />
          </span>
        </div>
      </Flex>
    </Flex>
  {/if}

  <Flex column align="start" fullwidth>
    <h3 class="left">Task List</h3>
    <Flex column fullwidth>
      {#each $tasks ?? [] as task, i (task.task.id)}
        <div
          class="row"
          role="button"
          tabindex={i}
          on:mousedown={() => {
            openTaskEvents.emit(task);
          }}
        >
          {#if task.task.icon}
            <SolidIcon {...task.task} />
          {/if}
          <p class="title">{task.task.title}</p>
          <span class="chip bgw">
            {#if !task.complate}
              <span class="reward">
                <Balance balance={task.task.reward} big symbol />
              </span>
              <Gif type="gem" size={15} />
            {:else}
              <span class="done">Done</span>
            {/if}
          </span>
        </div>
      {/each}
    </Flex>
  </Flex>
</Flex>

<style lang="sass">
  .title
    font-size: 14px
    font-weight: 600
    line-height: 18.2px
    text-align: left
    color: var(--white)
    flex-grow: 1

  .reward
    font-size: 14px
    font-weight: 600
    line-height: 16.8px
    color: var(--white)

  .left
    color: var(--white)

  h3
    color: #62646E

  .row
    display: flex
    gap: 15px
    height: 72px
    align-items: center

    &:not(:last-child)
      border-bottom: 1px solid var(--container)

  .done
    font-size: 14px
    font-weight: 600
    line-height: 16.8px
    text-align: center
    color: var(--strong-green)
</style>
