<script lang="ts">
  import { init } from "./library/init";
  import MenuItem from "./components/MenuItem.svelte";
  import MenuLayout from "./layout/MenuLayout.svelte";
  import Boost from "./views/Boost/Boost.svelte";
  import Earn from "./views/Earn.svelte";
  import Friends from "./views/Friends.svelte";
  import Home from "./views/Home/Home.svelte";
  import Effect from "./components/Effect.svelte";
  import Notifications from "./components/Notifications.svelte";
  import DailyReward from "./components/DailyReward.svelte";
  import Listing from "./views/Listing/Listing.svelte";
  import OpenTask from "./components/OpenTask.svelte";
  import Skins from "./views/Skins/Skins.svelte";
  import Profile from "./views/Profile/Profile.svelte";
  import LevelWather from "./components/LevelWather.svelte";
</script>

{#await init() then}
  <Notifications>
    <Effect>
      <OpenTask>
        <DailyReward>
          <MenuLayout>
            <MenuItem link="home" active title="Home" icon="favorite">
              <Home />
            </MenuItem>
            <MenuItem title="Frens" icon="friends">
              <Friends />
            </MenuItem>
            <MenuItem title="Mining" link="boosts" icon="games">
              <Boost />
            </MenuItem>
            <MenuItem title="Earn" icon="fire">
              <Earn />
            </MenuItem>
            <MenuItem title="Listing" icon="listing" link="listing">
              <Listing />
            </MenuItem>
            <MenuItem title="Profile" icon="profile" link="profile" hidden>
              <Profile />
            </MenuItem>
            <MenuItem title="Skins" icon="Skins" link="skins" hidden>
              <Skins />
            </MenuItem>
          </MenuLayout>

          <LevelWather />
        </DailyReward>
      </OpenTask>
    </Effect>
  </Notifications>
{/await}
