<script>
    import Badge from "../../../uikit/Badge.svelte";
    import {profile} from "../../../store/profile";
    import {levels} from "../../../store/levels";
    import Balance from "../../../uikit/Balance.svelte";
    import Flex from "../../../components/Flex.svelte";

    console.log($levels)
</script>


<div class="title">
    All Levels
</div>
<div>
    <div class="base header">
        <div>Level</div>
        <div>RXP Score</div>
    </div>

    <div class="wrapper">
        {#each $levels ?? [] as level, idx}
            <div class="base item {$profile?.level.currentUserLevel.id === level.id && 'active'}">
                <Flex gap={12}>
                    <Badge class="badge">
                        {idx+1}lvl
                    </Badge>
                    <div class="lvl">
                        {level.name}
                    </div>
                </Flex>
                <div>
                    <Balance balance={level.from} big />
                    -
                    {#if $levels?.[idx+1]}
                        <Balance balance={$levels?.[idx+1].from} big />
                    {:else}
                        <span>∞</span>
                    {/if}
                </div>
            </div>
        {/each}
    </div>
</div>

<style lang="scss">
  :global(.badge) {
    min-width: 34px;
  }

  .title {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
  }

  .wrapper {
    margin: 0 -16px;
  }
  .base {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #1A1B22;
  }

  .header {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #AEB0B7;
    padding: 7px 0;
  }

  .item {
    padding: 12px 16px !important;
    font-size: 14px;
    line-height: 130%;

    &.active {
      background: #24252D;
    }

    &:last-child {
      border: none;
    }

    & > *:first-child {
      color: #FFF;
      font-weight: 600;
    }

    & > *:last-child {
      color: #878892;
      font-weight: 500;
    }
  }

  .lvl {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
  }
</style>