<script lang="ts">
  import { TabsItem } from "./Tabs";
  import { click } from "../library/click";
  import { resize } from "../library/init";
  import HeaderNew from "./HeaderNew.svelte";

  export let icon: string;
  export let title: string;
  export let active: boolean = false;
  export let hidden: boolean = false;
  export let link: string | symbol = Symbol();
</script>


<TabsItem {active} {title} {link}>
  <slot name="button" slot="button" let:select let:active>
      {#if !hidden}
          <button
                  class={active ? "active" : ""}
                  use:click={() => {
                    resize();
                    select();
                  }}
          >
            <i class="icon-{icon}"/>
            <span>{title}</span>
          </button>
      {/if}
  </slot>

<!--    <div class="root">-->
<!--        <HeaderNew />-->
        <slot />
<!--    </div>-->
</TabsItem>

<style lang="sass">
  .root
    min-height: 100%
    height: 100%

  button
    display: flex
    flex-direction: column
    background: none
    border: none
    justify-content: center
    align-items: center
    color: var(--gray)
    font-size: 12px
    font-weight: 500
    line-height: 13.2px
    text-align: left

    i
      font-size: 22px

    &.active
      color: var(--white)
</style>
