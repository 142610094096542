<script lang="ts">
  import Flex from "../components/Flex.svelte";
  import { TabsView, Tabs } from "../components/Tabs";
  import {onMount} from "svelte";

  let content: HTMLDivElement | null = null;
  // $: openKeyboard = false
  // onMount(() => {
  //   const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => openKeyboard = true);
  //   const keyboardDidHideListener = Keyboard.addListener(
  //           'keyboardDidHide',
  //           () => {
  //             setKeyboardVisible(false); // or some other action
  //           }
  //   );
  // })

</script>

<Tabs on:change={() => setTimeout(() => content?.scrollTo(0, 0))}>
  <div class="flex">
    <div class="content" bind:this={content}>
      <TabsView />
    </div>
    <div class="menu">
      <slot />
    </div>
  </div>
</Tabs>

<style lang="sass">
  .flex
    display: flex
    height: 100%
    flex-direction: column

  .content
    flex-grow: 1
    overflow: auto
    position: relative

  .menu
    display: flex
    justify-content: space-around

</style>
