import { client } from "../library/fetch";
import { signal } from "@preact/signals-core";

export const levelsData = client.api.game.getUserLevels.get().then(({ data }) => data);
export const levels = signal<PromiseResult<typeof levelsData>>(null);

levelsData.then(data => levels.set(data));

export const updateLevels = () => {
  return client.api.game.getUserLevels.get()
    .then(({ data }) => levels.set(data));
};