export function outside<T extends HTMLElement>(el: T, callback: (e: { event: PointerEvent | TouchEvent | MouseEvent, currentTarget: T; }) => any) {
  const down = (event: MouseEvent | TouchEvent) => {
    const { target } = event;

    if (!(target instanceof HTMLElement))
      return;

    if (target !== el && !el.contains(target))
      callback({ event, currentTarget: el });
  };

  const object = {
    destroy() {
      removeEventListener('mousedown', down);
    },
    update() {
      this.destroy();
      addEventListener('mousedown', down);
    }
  };

  object.update();

  return object;
}