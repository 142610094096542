<script lang="ts">
  import WebApp from "@twa-dev/sdk";
  import { avatars } from "../images/avatars";
  import { getDefault } from "../library/getDevault";
  export let telegramId: string | number = WebApp.initDataUnsafe.user?.id ?? 0;
  export let size = 52;

  $: url = `/api/photo/${telegramId}`;

  const avatarsUrl = [...Object.values(avatars)];
</script>

<div
  class="avatar"
  style="
    width: {size}px;
    height: {size}px;
  "
>
  {#if url}
    {#await avatarsUrl[+telegramId % avatarsUrl.length]() then url}
      <div class="image" style="background-image: url({getDefault(url)})" />
    {/await}
    <div class="image" style="background-image: url({url});" />
  {/if}
</div>

<style lang="sass">
  .avatar
    border-radius: 100%
    overflow: hidden
    background-color: #999
    position: relative

    .image
      position: absolute
      width: 100%
      height: 100%
      inset: 0
      background-position: center
      background-size: contain
      background-repeat: no-repeat
</style>
