<script lang="ts">
  import Banner from "../../../components/Banner.svelte";
  import Flex from "../../../components/Flex.svelte";
  import * as banners from "../../../images/banners";
  import Gif from "../../../components/Gif.svelte";
  import Balance from "../../../uikit/Balance.svelte";
  import { tasks } from "../../../store/tasks";
  import { openTaskEvents } from "../../../components/OpenTask.svelte";
  import { tabActivate } from "../../../components/Tabs/TabsItem.svelte";
  import { notify } from "../../../components/Notifications.svelte";

  $: invite = $tasks?.filter((e) => e.task.icon === 'friends').filter(({complate}) => !complate)[0];
</script>

<div class="banners">
  <Flex gap={12} padding="0px 16px">
    {#if invite}
      <Banner
        on:click={() => openTaskEvents.emit(invite)}
        image={banners.invite}
      >
        <h5>{invite.task.title}</h5>
        <span class="chip white">
          <Gif type="gem" size={15} />
          <Balance balance={invite.task.reward} symbol />
        </span>
      </Banner>
    {/if}
    <Banner
      on:click={() => tabActivate.emit("listing")}
      style="blue"
      image={banners.whitelist}
    >
      <h5>Buy Whitelist for <br /> $RAX Pre-Sale</h5>
      <span class="chip white">
        100k
        <Gif type="gem" size={15} />
      </span>
    </Banner>
    <Banner
      style="orange"
      on:click={() => notify.emit("Soon")}
      image={banners.lottery}
    >
      <h5>Lottery Entry<br /> With Prize</h5>
      <span class="chip white">
        <Balance balance={25000} symbol />
      </span>
    </Banner>
    <Banner
      style="purple"
      on:click={() => notify.emit("Soon")}
      image={banners.nft}
    >
      <h5>NFT<br /> Key Mint</h5>
    </Banner>
    <Banner
      style="blue"
      on:click={() => notify.emit("Soon")}
      image={banners.rax}
    >
      <h5>Buy $RAX on <br /> Pre-Sale</h5>
    </Banner>
  </Flex>
</div>

<style lang="sass">
  .banners
    width: 100vw
    padding-bottom: 16px
    overflow-x: scroll
    flex-shrink: 0

  h5
    text-align: left

  .chip
    height: 25px
</style>
