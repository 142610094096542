import type { App } from "../../../backend/src";
import { retrieveLaunchParams } from "@tma.js/sdk";
import { treaty } from "@elysiajs/eden";
import ky from "ky";

try {
  retrieveLaunchParams();
} catch (e) {
  console.log('Blablacar');
}

// Wrapper with measurement of request time
const fetchWrapper = async (input: RequestInfo | URL, init?: RequestInit) => {
  const start = Date.now();
  const pathWithoutDomain = input.toString().replace(location.origin, '');
  const response = await fetch(input, init);
  const end = Date.now();
  const delta = end - start;
  const MAX_REQUEST_TIME = 500;
  if (delta > MAX_REQUEST_TIME) {
    console.warn(`[DEBUG] [TOO LONG] "${pathWithoutDomain}" took`, delta, 'ms');
  } else {
    console.debug(`[DEBUG] "${pathWithoutDomain}" took`, delta, 'ms');
  }
  return response;
};

export const api = ky.create({
  prefixUrl: 'https://3c82-37-214-75-187.ngrok-free.app',
  timeout: 10000,
  fetch: fetchWrapper,
  throwHttpErrors: false,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorage.getItem('token')
        request.headers.set('Authorization', token)
        request.headers.set('content-type', 'application/json; charset=utf-8')
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          localStorage.removeItem('token')
        }
        if (response.status >= 400) {
          throw await response.json()
        }
        return response.json()
      },
    ],

  },
})

export const client = treaty<App>(location.origin, {
  headers: {
    authorization: localStorage.getItem('token')
  },
  fetcher: fetchWrapper
});


