<script lang="ts">
    import Siema from 'siema'
    import {onMount, createEventDispatcher, afterUpdate} from 'svelte'
    import type {SiemaOptions} from "siema"

    export let perPage = 3
    export let loop = true
    export let autoplay = 0
    export let duration = 200
    export let easing = 'ease-out'
    export let startIndex = 0
    export let draggable = true
    export let multipleDrag = true
    export let dots = true
    export let controls = true
    export let threshold = 20
    export let rtl = false
    let currentIndex = startIndex;

    let siema: SiemaOptions['selector']
    let controller: Siema | any
    let timer: Timer
    const dispatch = createEventDispatcher()

    $: pips = controller ? controller.innerElements : []
    $: currentPerPage = controller ? controller.perPage : perPage
    $: totalDots = controller ? Math.ceil(controller.innerElements.length / currentPerPage) : []
    $: controller && Number.isInteger(startIndex) && controller.goTo(startIndex)

    onMount(() => {
        controller = new Siema({
            selector: siema,
            perPage: typeof perPage === 'object' ? perPage : Number(perPage),
            loop,
            duration,
            easing,
            startIndex,
            draggable,
            multipleDrag,
            threshold,
            rtl,
            onChange: handleChange
        })

        if(autoplay) {
            timer = setInterval(right, autoplay);
        }
        return () => {
            autoplay && clearInterval(timer)
            controller.destroy()
        }
    })

    export function isDotActive (currentIndex: number, dotIndex: number) {
        if (currentIndex < 0) currentIndex = pips.length + currentIndex;
        return currentIndex >= dotIndex*currentPerPage && currentIndex < (dotIndex*currentPerPage)+currentPerPage
    }

    export function left () {
        controller.prev()
    }

    export function right () {
        controller.next()
    }

    export function go (index: number) {
        controller.goTo(index)
    }

    export function pause() {
        clearInterval(timer);
    }

    export function resume() {
        if (autoplay) {
            timer = setInterval(right, autoplay);
        }
    }

    function handleChange () {
        currentIndex = controller.currentSlide
        dispatch('change', {
            currentSlide: controller.currentSlide,
            slideCount: controller.innerElements.length
        } )
    }

    function resetInterval(node, condition) {
        function handleReset() {
            pause();
            resume();
        }

        if(condition) {
            node.addEventListener('click', handleReset);
        }

        return {
            destroy() {
                node.removeEventListener('click', handleReset);
            }
        }
    }
</script>

<div class="carousel">
    <div class="slides" bind:this={siema}>
        <slot></slot>
    </div>
    {#if controls}
        <button class="left" on:click={left} use:resetInterval={autoplay} aria-label="left">
            <slot name="left-control"></slot>
        </button>
        <button class="right" on:click={right} use:resetInterval={autoplay} aria-label="right">
            <slot name="right-control"></slot>
        </button>
    {/if}
    {#if dots}
        <ul class="dots">
            {#each {length: totalDots} as _, i}
                <li on:click={() => go(i*currentPerPage)} class={isDotActive(currentIndex, i) ? "active" : ""}></li>
            {/each}
        </ul>
    {/if}
</div>

<style>
    .carousel {
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    button {
        position: absolute;
        width: fit-content;
        height: fit-content;
        top: 50%;
        z-index: 50;
        margin-top: -20px;
        border: none;
        background-color: transparent;
    }
    button:focus {
        outline: none;
    }

    .left {
        left: 2vw;
    }

    .right {
        right: 2vw;
    }
    .dots {
        list-style-type: none;
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: -30px;
        padding: 0;
    }
    .dots li {
        margin: 6px;
        border-radius: 100%;
        background-color: rgba(255,255,255,0.5);
        height: 8px;
        width: 8px;
    }
    .dots li:hover {
        background-color: rgba(255,255,255,0.85);
    }
    .dots li.active {
        background-color: rgba(255,255,255,1);
    }
</style>