import {api, client} from "../library/fetch";
import {signal} from "@preact/signals-core";

// export const getBoosts = () => api.get('api/game/boosts').json()
// getBoosts().catch((e) => {
//     console.log(e)
// })
export const boostListData = client.api.game.boosts.get().then(({ data }) => data)
export const boostList =  signal<PromiseResult<typeof boostListData>>(null)

boostListData.then(data => {
    data.boosts = data?.boosts.filter(({url}) => url);
    boostList.set(data)
})

export const updateBoost = () => {
    return client.api.game.boosts.get()
        .then(({ data }) => {
            data.boosts = data?.boosts.filter(({url}) => url);
            boostList.set(data)
        });
};

export const buyBoost = async (boostId: number): Promise<any> => {
    const response = await client.api.game.boosts.buy.get({
        query: { id: String(boostId) }
    });
    if (response.error) {
        throw response.error.value;
    }
}