<script lang="ts">
    import discoBall from '../assets/disco-ball.png'
    import bg from '../assets/green-bg.png'
    import Flex from "../../../components/Flex.svelte";
    import WebApp from "@twa-dev/sdk";
</script>

<div class="root">
    <div class="bg" style="background-image: url({bg})" />

    <Flex column gap={20} align="center">
        <img src={discoBall} alt="">
        <Flex column gap={12} >
            <h3>Whitelist Accepted</h3>
            <span>Now You can Buy $RAX Token Allocation by this link below.</span>
        </Flex>

        <button
                on:click={() => WebApp.openLink('https://app.ray.sx/')}
        >
            Buy $RAX Allocation
        </button>
    </Flex>

</div>

<style lang="scss">
    .root {
      position: relative;
      padding: 20px 16px;
      border-radius: 32px;
      border: 1px solid rgba(255, 255, 255, 0.13);
      background: #14151B;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .bg {
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      inset: 0;
      z-index: 0;
    }

    img {
      width: 64px;
      height: 64px;
      z-index: 1;
    }

    span {
      color: #AEB0B7;
      font-size: 13px;
      font-weight: 600;
      line-height: 120%;
      padding: 0 16px;
    }

    button {
      z-index: 1;
      border: none;
      background: var(--Purple-hover, #8702E0);
    }
</style>
