<script lang="ts">
    import Modal from "./Modal.svelte";
    import { profile } from "../store/profile";
    import { boostList } from "../store/boosts";
    import { onMount } from "svelte";
    import * as levelsAvatar from "../images/avatars/dragons";
    import Flex from "./Flex.svelte";

    const LVL = 'current_lvl'

    $: currectLvl = $profile?.level.currentUserLevel.id
    let openLvl = null as number | null
    $: avatar = `lvl${openLvl}` as keyof typeof levelsAvatar
    $: boosts = $boostList?.boosts.filter(({ opensAtLevel }) => opensAtLevel === openLvl)

    $: if (localStorage.getItem(LVL) && currectLvl !== Number(localStorage.getItem(LVL))) {
        openLvl = currectLvl
    }

    onMount(() => {
        const lvlStorage = localStorage.getItem(LVL) as number | null

        if (!lvlStorage) {
            localStorage.setItem(LVL, String(currectLvl))
            return
        }

        if (Number(lvlStorage) !== currectLvl) {
            openLvl = currectLvl
        }
    })

    const handlerClose = () => {
        openLvl && localStorage.setItem(LVL, String(openLvl))
        openLvl = null
    }
</script>

{#if (typeof openLvl === "number")}
    <Modal
        on:close={handlerClose}
        zIndex={10}
    >
        <div class="root">
            <img
                    src={levelsAvatar[avatar]}
                    class="skin"
                    alt=""
            />

            <Flex column gap={12}>
                <h3>
                    Level Up!
                </h3>
                <div class="description">
                    Congratulations! You got Lvl. {openLvl} — {$profile?.level.currentUserLevel.name}.
                </div>
            </Flex>

            {#if boosts.length}
                <h4>
                    New Mining Boosts Available:
                </h4>

                <div class="boosts">
                    {#each boosts ?? [] as boost}
                        <div class="boost">
                            <img
                                    alt=""
                                    src={boost.url}
                            />
                            <div>
                                {boost.name}
                            </div>
                        </div>
                    {/each}
                </div>
            {/if}

            <button class="primary" on:click={handlerClose}>
                Cool
            </button>
        </div>
    </Modal>
{/if}

<style lang="scss">
  .root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .skin {
    background-size: contain;
    background-repeat: no-repeat;
    width: 244px;
    height: 244px;
    border-radius: 29.283px;
    border: 1.046px solid rgba(255, 255, 255, 0.14);
  }

  .description {
    color: #AEB0B7;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
  }

  .boosts {
    width: calc(100% + 16px * 2);
    display: flex;
    gap: 12px;
    overflow-x: scroll;
    margin-inline: -16px;
    padding-inline: 16px;

    & > .boost {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #878892;
      font-size: 11px;
      font-weight: 600;
      line-height: 120%;

      & > img {
        width: 90px;
        height: 90px;
        border-radius: 16px;
        border: 1px solid #313238;
      }
    }
  }
</style>