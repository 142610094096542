<script lang="ts">
    // import {shopList} from "../../store/shop";
    import Flex from "../../components/Flex.svelte";
    import CarouselSkins from "./components/CarouselSkins.svelte";
    import Stats from "./components/Stats.svelte";
    import Levels from "./components/Levels.svelte";
    import HeaderNew from "../../components/HeaderNew.svelte";
    import MyLvl from "./components/MyLvl.svelte";
    import TonConnect from "./components/TonConnect.svelte";
    // import {tabActivate} from "../../components/Tabs";
    import Icon from "../../components/Icon.svelte";

    // $: list = $shopList ?? [];


</script>

<HeaderNew withMargin />
<div class="root">
    <!--  <ul>-->
    <!--    {#each list as item}-->
    <!--      <button on:mousedown={makeInvoiceLink} class="primary">-->
    <!--        {item.alias} | {item.price} start-->
    <!--      </button>-->
    <!--    {/each}-->
    <!--  </ul>-->
    <TonConnect />
    <MyLvl />
    <CarouselSkins />
    <Stats />
    <Levels />
</div>

<style>
    .root {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }
</style>