<script lang="ts">
  import { signal } from "@preact/signals-core";
  import { currentCtx, referalCtx } from "./TabsCtx";
  import { createEventDispatcher } from "svelte";
  const current = signal<any>(null);
  const referal = signal<HTMLElement | null>(null);

  const dispatcher = createEventDispatcher<{ change: void }>();

  var init = false;

  currentCtx.provide(current);
  referalCtx.provide(referal);

  $: {
    if (init && $current) {
      dispatcher("change");
    }

    init = true;
  }
</script>

<slot />
