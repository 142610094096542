import {client} from "../library/fetch";
import {signal} from "@preact/signals-core";
import type {UserSkin} from "../../../backend/src/class/UserSkin";

export const userSkinListData: Promise<Array<UserSkin>> = client.api.game.getUserSkins.get().then(({ data }) => data || []);
export const userSkinList = signal<PromiseResult<typeof userSkinListData>>([]);

userSkinListData.then(data => userSkinList.set(data));

export const generateSkinPurchaseUrl = async (skinId: number): Promise<string | null> => {
    const response = await client.api.game.getSkinsInvoice.get({
        query: {
            id: skinId.toString()
        }
    });

    return response.data;
}

export const buyWhiteList = async () => client.api.game.buyWhitelist.get()