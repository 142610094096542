<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let image: string = "";
  export let style: "gray" | "blue" | "orange" | "purple" = "gray";

  const dispatch = createEventDispatcher<{ click: void }>();
</script>

<div
  class="banner"
  role="button"
  tabindex="0"
  on:mousedown={() => dispatch("click")}
  style={image ? `--image: url(${image})` : ""}
  data-style={style}
>
  {#if image}
    <div class="image" style="background-image: url({image});" />
  {/if}
  <div class="content">
    <slot />
  </div>
</div>

<style lang="sass">
  .banner
    --image: none
    border-radius: 20px
    position: relative
    flex-grow: 1
    height: 92px
    flex-basis: calc(50% - 8px)
    flex-shrink: 0

    .content, .image
      position: absolute
      inset: 0

    .image
      background-position: right center
      background-size: cover
      background-repeat: no-repeat

    .content
      display: flex
      flex-direction: column
      justify-content: center
      align-items: flex-start
      padding: 0px 14px
      gap: 6px

    &[data-style=gray]
      background:  #24252D

    &[data-style=blue]
      background: linear-gradient(118.65deg, #2346CF 15.67%, #B518FF 91.74%)

    &[data-style=orange]
      background:  linear-gradient(129.14deg, #FF2727 0.28%, #EA7C2C 55.77%, #FF3D00 108.64%)

    &[data-style=purple]
      background: linear-gradient(118.65deg, #931ADD 15.67%, #FF1887 91.74%)




</style>
