<script lang="ts">
  import { client } from "../library/fetch";
  import { friends } from "../store/friends";
  import Avatar from "./Avatar.svelte";
  import Balance from "../uikit/Balance.svelte";
  import Flex from "./Flex.svelte";
  import Gif from "./Gif.svelte";
  import Level from "./Level.svelte";
  import Badge from "../uikit/Badge.svelte";

  let friendsList = [...($friends?.users ?? [])];
  let total = $friends?.totalCount ?? 0;

  let limit = 10;
  let page = -1;
  let promise = loadMore();

  async function loadMore(e?: Event) {
    e?.preventDefault();

    if ((page + 1) * limit > total) return;

    await client.api.game.friends
      .get({ query: { peerPage: limit, page: ++page } })
      .then(({ data }) => {
        total = data?.totalCount ?? $friends?.totalCount ?? 0;
        if (!page) friendsList = [];

        friendsList = [...friendsList, ...(data?.users ?? [])];
      })
      .catch(console.error);
  }
</script>

<Flex column fullwidth gap={20}>
  <Flex column fullwidth gap={12}>
    <h2 class={total ? "white" : "gray"}>
      {total} Frens
    </h2>

    {#if total}
      <table>
        <tbody>
          {#each friendsList as user (user.id)}
            <tr>
              <td>
                <Flex gap={20}>
                  <Avatar size={40} telegramId={user.telegramId} />
                  <Flex column align="start" gap={6}>
                    <Flex align="center" justify="start" gap={8}>
                      <p class="nick">{user.name}</p>
<!--                      <Level-->
<!--                        size={12}-->
<!--                        icon={user.level?.current.icon ?? "wood"}-->
<!--                      />-->
                    </Flex>
                    <Flex gap={8}>
                      <Badge>
                        {user.level.currentUserLevelIndex+1}lvl
                      </Badge>
                      <Flex gap={4}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_8201_26136)">
                            <path d="M-0.296875 14.9014V12.4542C-0.296875 11.9845 -0.181931 11.5601 0.0479583 11.181C0.277847 10.8019 0.581458 10.5135 0.958792 10.3159C1.69479 9.93808 2.45312 9.65475 3.23379 9.46586C4.01446 9.27697 4.80746 9.18253 5.61279 9.18253C6.41946 9.18253 7.21201 9.27697 7.99046 9.46586C8.7689 9.65475 9.52346 9.93675 10.2541 10.3119C10.6342 10.5092 10.9386 10.797 11.1671 11.1754C11.3957 11.5538 11.51 11.98 11.51 12.454V14.9014H-0.296875ZM12.9745 14.9014V12.2742C12.9745 11.7942 12.846 11.2777 12.5891 10.7249C12.3322 10.172 11.9316 9.68514 11.3871 9.26436C12.0392 9.33636 12.6544 9.46069 13.2326 9.63736C13.8107 9.81403 14.349 10.0389 14.8473 10.3119C15.3122 10.5706 15.6699 10.8721 15.9205 11.2164C16.1711 11.5606 16.2965 11.9172 16.2965 12.2862V14.9014H12.9745ZM5.60012 8.33103C4.73579 8.33103 3.99751 8.02491 3.38529 7.41269C2.77296 6.80036 2.46679 6.06336 2.46679 5.20169C2.46679 4.33736 2.77296 3.59908 3.38529 2.98686C3.99751 2.37453 4.73579 2.06836 5.60012 2.06836C6.46179 2.06836 7.19946 2.37453 7.81312 2.98686C8.42668 3.59908 8.73346 4.33736 8.73346 5.20169C8.73346 6.06336 8.42668 6.80036 7.81312 7.41269C7.19946 8.02491 6.46179 8.33103 5.60012 8.33103ZM13.6676 5.20169C13.6676 6.06069 13.3608 6.79703 12.7473 7.41069C12.1338 8.02425 11.3977 8.33103 10.5388 8.33103C10.3743 8.33103 10.157 8.31225 9.88679 8.27469C9.61646 8.23714 9.39529 8.18925 9.22329 8.13103C9.54818 7.71147 9.79185 7.25275 9.95429 6.75486C10.1167 6.25686 10.198 5.73936 10.198 5.20236C10.198 4.66525 10.1167 4.14686 9.95429 3.64719C9.79185 3.14753 9.54818 2.68791 9.22329 2.26836C9.43762 2.19058 9.65613 2.1378 9.87879 2.11003C10.1015 2.08225 10.3206 2.06836 10.5363 2.06836C11.3958 2.06836 12.1326 2.37514 12.7466 2.98869C13.3606 3.60236 13.6676 4.34003 13.6676 5.20169Z" fill="#62646E"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_8201_26136">
                              <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>

                        <p class="count">
                          {user.inviteCount}
                        </p>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </td>
              <td>
                <Flex align="center" justify="end" gap={4}>
                  <p class="balance">
                    <Balance big balance={user.balance} />
                  </p>
                  <Gif type="gem" size={15} />
                </Flex>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/if}
  </Flex>
  {#await promise}
    <p>Loading...</p>
  {:then _}
    {#if friendsList.length < total}
      <a
        on:mousedown={(e) => {
          e.preventDefault();
          promise = loadMore();
        }}
        href="#loadmore">Load More</a
      >
    {/if}
  {/await}
</Flex>

<style lang="sass">
  table
    width: 100%
    border-spacing: 0

    tr td
      height: 72px
      border-bottom: 1px solid var(--container)

  .balance
    font-family: Work Sans
    font-size: 17px
    font-weight: 600
    line-height: 20.4px
    text-align: right
    color: var(--white)

  a
    font-family: Work Sans
    font-size: 15px
    font-weight: 600
    line-height: 18px
    text-align: center
    text-decoration: none
    color: var(--strong-up)


  .nick
    font-family: Work Sans
    font-size: 15px
    font-weight: 600
    line-height: 19.5px
    text-align: left
    color: var(--white)

  .count
    font-size: 14px
    font-weight: 500
    line-height: 120%
    text-align: left
    color: var(--white)

    i
      color: var(--gray-dark)
      font-size: 16px !important
</style>
