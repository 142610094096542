const divide = {
  ms: 1000,
  s: 60,
  m: 60,
  h: 24,
  D: 30,
  M: 12,
  Y: 1,
};

const divideKeys = [...Object.keys(divide)];
const divideValues = [...Object.values(divide)];

export class Time {

  static from(input: string) {
    var sum = 0;

    input.replace(/(\d+|\d*\.\d+)\s*(\w+)/g, (_, a, c) => {
      var segment = +a;

      if (c in divide) {
        const index = divideKeys.indexOf(c);

        for (var i = index - 1; i >= 0; i--) {
          segment *= divideValues[i];
        }
      }

      if (!isNaN(segment))
        sum += segment;

      return '';
    });

    return sum;
  }

  static remain(input: number) {
    input = Math.abs(input) | 0;

    var out: [number, string][] = [];

    for (var i = 0; i < divideValues.length; i++) {
      out.push([((input /= (divideValues[i - 1] ?? 1)) % divideValues[i]) | 0, divideKeys[i]]);
    }

    return out;
  }

  static format(input: number, required = true, milliseconds = false) {
    return this.remain(input)
      .slice(+!milliseconds)
      .reverse()
      .filter(e => !(!e[0] && required))
      .map(e => e.join(''))
      .join(' ');
  }
}