<script lang="ts">
  import * as gifs from "../images/gifs";
  export let type: keyof typeof gifs;
  export let size: number | null = null;
  export let className = "";
</script>

<i
  class={className}
  style="
    background-image: url({gifs[type]});
    display: {size ? 'inline-block' : 'inline'};
    width: {size ? `${size}px` : 'auto'};
    height: {size ? `${size}px` : 'auto'};
  "
>
  #
</i>

<style lang="sass">
  i
    color: transparent
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    background-color: transparent
</style>
