import { client } from "../library/fetch";
import { delay } from "../library/delay";
import { signal } from "@preact/signals-core";

export const tasksData = client.api.game.task.get().then(({ data }) => data);
export const tasks = signal<PromiseResult<typeof tasksData>>(null);
export const updateTasks = () => {
  return client.api.game.task.get()
    .then(({ data }) => data && tasks.update(() => data));
};

tasksData.then((data) => tasks.update(() => data));

async function loop() {
  while (true) {
    await updateTasks()
      .catch(console.error);
    await delay(100_000);
  }
}

loop();