<script lang="ts">
    import {createEventDispatcher} from "svelte";

    export let index: number
    export let data: Array<{ index: number, name: string }>

    const dispatch = createEventDispatcher();

    const isActive = (needIndex: number, index: number) => {
        return needIndex === index
    }

    const handlerClick = (index: number) => {
        dispatch('change', index)
    }
</script>

<div class="root">
    {#each data ?? [] as item}
        <div data-active={isActive(item.index, index)} on:click={() => handlerClick(item.index)}>{item.name}</div>
    {/each}
</div>

<style lang="scss">
  .root {
    z-index: 2;
    background: #090A0E;
    top: 0;
    position: sticky;
    white-space: nowrap;
    display: flex;
    gap: 8px;
    margin-inline: -16px;
    padding-inline: 16px;
    overflow-x: auto;
    min-height: 44px;
  }

  .root > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 0 14px;
    color: #AEB0B7;
    font-size: 15px;
    font-weight: 600;
    line-height: 120%;

    &[data-active="true"] {
      color: #FFF;
      border-radius: 16px;
      background: linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%);
    }
  }
</style>