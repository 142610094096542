import { computed, signal } from "@preact/signals-core";

import { client } from "../library/fetch";
import { delay } from "../library/delay";

export const dailyData = client.api.game.daily.get().then(({ data }) => data);
export const daily = signal<PromiseResult<typeof dailyData>>(null);
export const updateDaily = () => {
  return client.api.game.daily.get()
    .then(({ data }) => daily.set(data));
};

dailyData.then(data => daily.set(data));

async function loop() {
  while (true) {
    await updateDaily()
      .catch(console.error);
    await delay(30_000);
  }
}

loop();

export const dailyListData = client.api.game.daily.list.get().then(({ data }) => data);
export const dailyList = signal<PromiseResult<typeof dailyListData>>(null);
export const updateListDaily = () => {
  return client.api.game.daily.list.get()
    .then(({ data }) => dailyList.set(data));
};

export const currentDaily = computed(() => {
  const list = (dailyList.value ?? []);
  return list.findLast((e, i, d) => !e.claimed && d[i - 1]?.claimed) ?? list[0];
});

dailyListData.then(data => dailyList.set(data));
