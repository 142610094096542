<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.49944 9.35938H18.5586L9.49944 18.6686V9.35938Z" fill="url(#paint0_linear_8942_373)"/>
    <path d="M9.5 9.36005V0.0507812L0.440845 9.36005H9.5Z" fill="url(#paint1_linear_8942_373)"/>
    <path d="M9.5 9.35869V18.668L0.440845 9.35869H9.5Z" fill="url(#paint2_linear_8942_373)"/>
    <path d="M9.49944 9.35938H18.5586L9.49944 0.0501027V9.35938Z" fill="url(#paint3_linear_8942_373)"/>
    <rect width="6.49482" height="6.49482" transform="matrix(-0.697414 -0.716669 -0.697414 0.716669 14.0293 9.35938)" fill="url(#paint4_linear_8942_373)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1621 9.35938L9.49858 4.56709L4.83505 9.35938L9.49858 14.1517L14.1621 9.35938ZM13.8942 9.35938L9.49858 13.8764L5.10295 9.35938L9.49858 4.84239L13.8942 9.35938Z" fill="url(#paint5_linear_8942_373)"/>
    <defs>
        <linearGradient id="paint0_linear_8942_373" x1="9.93724" y1="12.2138" x2="12.6009" y2="15.1779" gradientUnits="userSpaceOnUse">
            <stop stop-color="#65B5FF"/>
            <stop offset="1" stop-color="#64E4FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_8942_373" x1="-2.18901" y1="11.7279" x2="2.01635" y2="-0.258786" gradientUnits="userSpaceOnUse">
            <stop offset="0.14601" stop-color="#8EECFF"/>
            <stop offset="1" stop-color="#A8EFFF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_8942_373" x1="1.15437" y1="8.64844" x2="4.40932" y2="21.3312" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2E3DB0"/>
            <stop offset="1" stop-color="#55C1F2"/>
        </linearGradient>
        <linearGradient id="paint3_linear_8942_373" x1="7.86251" y1="-0.0814245" x2="18.9029" y2="2.29142" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2354C0"/>
            <stop offset="0.0001" stop-color="#2354C0"/>
            <stop offset="1" stop-color="#A3E9FF"/>
        </linearGradient>
        <linearGradient id="paint4_linear_8942_373" x1="-1.40122" y1="2.6496" x2="8.05283" y2="2.21511" gradientUnits="userSpaceOnUse">
            <stop offset="0.0203454" stop-color="#2A67C4"/>
            <stop offset="0.349209" stop-color="#2E3AB0"/>
            <stop offset="1" stop-color="#79E8FF"/>
        </linearGradient>
        <linearGradient id="paint5_linear_8942_373" x1="9.46265" y1="4.52633" x2="9.45831" y2="13.4302" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D7F8FF"/>
            <stop offset="0.176669" stop-color="#9EEDFF"/>
            <stop offset="0.432173" stop-color="#66E3FF"/>
            <stop offset="0.685688" stop-color="white" stop-opacity="0"/>
        </linearGradient>
    </defs>
</svg>