<script lang="ts">
  import * as icons from "../images/color-icons";
  export let icon: keyof typeof icons;
  export let size = 32;
</script>

<div
  style="
    width: {size}px; 
    height: {size}px; 
    background-image: url({icons[icon]})
  "
/>

<style lang="sass">
  div
    display: inline-block
    background-repeat: no-repeat
    background-size: cover
    background-position: center
</style>
