import { client } from "../library/fetch";
import { delay } from "../library/delay";
import { signal } from "@preact/signals-core";

export const leadersData = client.api.game.leaderboard.get({ query: {} }).then(({ data }) => data);
export const leaders = signal<PromiseResult<typeof leadersData>>(null);
export const updateLeaders = () => {
  return client.api.game.leaderboard.get({ query: {} })
    .then(({ data }) => leaders.set(data));
};

leadersData.then(data => leaders.set(data));

async function loop() {
  while (true) {
    await updateLeaders()
      .catch(console.error);
    await delay(50_000);
  }
}

loop();