import { getContext, setContext } from "svelte";

export const context = <T>(initial: T) => {
  const _ctx = Symbol();
  return {
    provide(value: T) {
      setContext(_ctx, value ?? initial);
    },
    inject(): T {
      return getContext(_ctx);
    }
  };
};