<script lang="ts" context="module">
  export const tabActivate = events<[link: string | symbol]>();
</script>

<script lang="ts">
  import { signal } from "@preact/signals-core";
  import Portal from "../Portal.svelte";
  import { currentCtx, referalCtx } from "./TabsCtx";
  import WebApp from "@twa-dev/sdk";
  import { click } from "../../library/click";
  import { events } from "../../library/events";
  import { onEffect } from "../../hooks/onEffect";

  export let title: string = "Unnamed";
  export let link: string | symbol = Symbol();
  export let active: boolean = false;

  const ref = referalCtx.inject() ?? signal(null);
  const current = currentCtx.inject();

  function select() {
    if (current.value === link) return;
    current.value = link;
    WebApp.HapticFeedback.impactOccurred("light");
  }

  $: $current === null && select();
  $: active = $current === link;
  if (active) select();

  onEffect(() => {
    return tabActivate.sub((_link) => {
      if (_link === link) select();
    });
  });
</script>

<slot name="button" {active} {select}>
  <button use:click={select} class={active ? "gradient" : "none"}>
    {title}
  </button>
</slot>

{#if active}
  <div hidden>
    <Portal target={$ref}>
      <slot />
    </Portal>
  </div>
{/if}
