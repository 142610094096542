<script lang="ts">
  import { signal } from "@preact/signals-core";
  import { referalCtx } from "./TabsCtx";

  const ref = referalCtx.inject() ?? signal(null);
</script>

<div bind:this={$ref} />

<style lang="sass">
  div
    display: contents
</style>
