<script lang="ts" context="module">
  export const effect = events();
</script>

<script lang="ts">
  import JSConfetti from "js-confetti";
  import { events } from "../library/events";
  import { onEffect } from "../hooks/onEffect";

  let count = 0;
  $: show = count > 0;
  let width = 0;
  let height = 0;
  let can: HTMLCanvasElement | null = null;

  $: confeti = can ? new JSConfetti({ canvas: can }) : null;
  $: if (can) {
    can.width = width;
    can.height = height;
  }

  async function add(emoji = false) {
    count++;
    await confeti?.addConfetti({
      ...(emoji
        ? { emojis: ["🌟", "⚡️", "💥", "✨", "🔶", "⭐️"] }
        : {
            confettiColors: [
              "#3805d1",
              "#3741ea",
              "#1018a2",
              "#000eff",
              "#363b8f",
              "#3139c1",
            ],
          }),
      emojiSize: 60,
      confettiRadius: 10,
      confettiNumber: 25,
    });
    count--;
  }

  onEffect(() =>
    effect.sub(() => {
      add(true);
      add(false);
    }),
  );
</script>

<slot />

<div class="effect {show ? 'show' : ''}">
  <canvas bind:this={can} bind:offsetWidth={width} bind:offsetHeight={height} />
</div>

<style lang="sass">
  .effect
    position: fixed
    inset: 0
    background-color: rgba(0,0,0,0)
    backdrop-filter: none
    transition: 0.4s
    pointer-events: none
    z-index: 10
    

    &.show
      background-color: rgba(0,0,0,.4)
      backdrop-filter: blur(10px)
      pointer-events: all

    canvas
      position: absolute
      inset: 0px
      width: 100%
      height: 100%
      pointer-events: none
</style>
