<script lang="ts">
    import Flex from "../../../components/Flex.svelte";
    import {profile} from "../../../store/profile";
    import Balance from "../../../uikit/Balance.svelte";

    $: exFrom = $profile!.level!.currentUserLevel.from
    $: exNext = $profile!.level.nextUserLevel?.from
    $: level = $profile!.level.currentUserLevelIndex + 1;
    $: levelName = $profile!.level.currentUserLevel.name;
    $: needNextLevel = $profile!.level.nextUserLevel
        ? $profile!.level.nextUserLevel.from - $profile!.level.currentUserLevel.from
        : 0;
    $: remainingNextLevel = $profile!.level.nextUserLevel
        ? $profile!.level.nextUserLevel.from - $profile!.experience
        : 0;
    $: persent =
        (needNextLevel ? 1 - remainingNextLevel / needNextLevel : 0) * 100;
</script>

<Flex justify="center" align="center" className="lvl_title" gap={4}>
    <span class="score">{level} lvl</span>
    <span class="lvl">{levelName}</span>
</Flex>
<Flex column gap={4}>
    <Flex justify="between">
        <div class="score">
            <Balance balance={exFrom} big />
        </div>
        <Flex align="center" gap={4}>
            <span class="lvl">{level}LvL</span>
            <span class="percent">{persent.toFixed(1)}%</span>
        </Flex>
        <div class="score">
            <Balance balance={exNext} big />
        </div>
    </Flex>
    <div class="progress" style="--persent: {persent}%"></div>
</Flex>

<style lang="scss">
  :global(.lvl_title > *)  {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 120% !important;
  }

  .score {
    color: #AEB0B7;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
  }

  .lvl {
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
  }

  .percent {
    background: linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
  }

  .progress {
    --persent: 0%;
    display: block;
    width: 100%;
    height: 10px;
    background-color: #24252D;
    border-radius: 16px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: var(--persent);
      background: linear-gradient(118.65deg, #587BFF 15.67%, #B518FF 91.74%);
      border-radius: 16px;
    }
  }
</style>