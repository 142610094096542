<script>
    import Gif from "../components/Gif.svelte";
    import Flex from "../components/Flex.svelte";

    export var up = false;
    export var size = 16;
</script>

<div class="root" style={`width: ${size}px; height: ${size}px;`}>
    <Gif type="gem" size={size} />
    {#if up}
        <Flex column className="up">
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00035 5.77237L4.31854 4.04303L0.636719 5.77237L4.31854 0.863281L8.00035 5.77237Z" fill="#6DFFA8"/>
            </svg>
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00035 5.77237L4.31854 4.04303L0.636719 5.77237L4.31854 0.863281L8.00035 5.77237Z" fill="#6DFFA8"/>
            </svg>
        </Flex>
    {/if}
</div>

<style lang="scss">
    .root {
      position: relative;
    }
    :global(.up) {
      position: absolute;
      bottom: 0;
      right: -5px;
    }
</style>