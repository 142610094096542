export function click<T extends HTMLElement>(el: T, callback: (e: { event: PointerEvent | TouchEvent | MouseEvent, currentTarget: T; }) => any) {
  let touched = false;

  const touch = (event: TouchEvent) => {
    if (touched) return;
    event.preventDefault();
    callback({ event, currentTarget: el });
  };

  const down = (event: MouseEvent) => {
    if (touched) return;
    callback({ event, currentTarget: el });
  };

  const object = {
    destroy() {
      el.removeEventListener('mousedown', down);
      el.removeEventListener('touchstart', touch);
    },
    update() {
      object.destroy();
      el.addEventListener('mousedown', down);
      el.addEventListener('touchstart', touch);
    }
  };

  addEventListener('touchstart', () => {
    touched = true;
  });

  addEventListener('touchend', () => {
    touched = false;
  });

  object.update();

  return object;
}