<script lang="ts">
    export let value: number
</script>

<div class={`base b-${Math.trunc(value)} ${Number.isInteger(value) && 'integer'}`}>
    x{value}
</div>

<style>
    .base {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 700;
        line-height: 120%;
    }

    .integer {
        font-size: 20px !important;
    }

    .b-1 {
        border: 2px solid rgba(255, 255, 255, 0.23);
        background: linear-gradient(124deg, #FF8425 4.02%, #C33FE6 34.64%, #7000C5 74.08%);
    }

    .b-2 {
        border: 2px solid rgba(255, 255, 255, 0.23);
        background: linear-gradient(124deg, #9FFFB4 4.02%, #26BE00 34.64%, #008849 74.08%);
    }

    .b-3 {
        border: 2px solid rgba(255, 255, 255, 0.23);
        background: linear-gradient(124deg, #6893FF 4.02%, #3F47E6 34.64%, #0700C5 74.08%);
    }

    .b-4 {
        border: 2px solid rgba(255, 255, 255, 0.23);
        background: linear-gradient(124deg, #FFCC92 4.02%, #FD7600 34.64%, #A90000 107.96%);
    }

    .b-5 {
        border: 2px solid rgba(255, 255, 255, 0.23);
        background: linear-gradient(124deg, #FFF6C0 4.02%, #FFFEFD 18.99%, #FFB83D 29.64%, #87541A 49.59%, #885223 76.7%, #CFA156 96.22%);
    }
</style>