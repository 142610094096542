import { SHOW_FRIENDS_LIMIT } from "../config";
import { client } from "../library/fetch";
import { delay } from "../library/delay";
import { signal } from "@preact/signals-core";

export const friendsData = client.api.game.friends.get({ query: { peerPage: SHOW_FRIENDS_LIMIT } }).then(({ data }) => data);
export const friends = signal<PromiseResult<typeof friendsData>>(null);
export const updateFriends = () => {
  return client.api.game.friends.get({ query: { peerPage: SHOW_FRIENDS_LIMIT } })
    .then(({ data }) => friends.set(data));
};

friendsData.then(data => friends.set(data));

async function loop() {
  while (true) {
    await updateFriends()
      .catch(console.error);
    await delay(10_000);
  }
}

loop();