<script lang="ts" context="module">
  const _small = [3, 6, 9, 12, 15];
  const _smallKey = ["k", "M", "B", "T", "aa"];

  export function format(n: number, f = 0, s = 0) {
    var current = Math.abs(+n.toFixed(f)).toString();
    var out: string[] = [];

    while (current.length) {
      out.unshift(current.slice(-3));
      current = current.slice(0, -3);
    }

    return out.join(",");
  }

  export function toSmall(n: number, f = 2, s = 3) {
    if (n < 10 ** s) return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    for (let i = 0; i < _small.length; i++) {
      const _n = 10 ** _small[i + 1];
      const _nn = 10 ** _small[i];

      if (n < _n)
        return (n / _nn).toFixed(f).replace(/\.?0*$/, "") + _smallKey[i];
    }

    return "+1000" + _smallKey.slice(-1)[0];
  }

  export const formatBig = (input: number, f?: number): string => {
    return toSmall(input, f);
  };
</script>

<script lang="ts">
  import { onFrame } from "../hooks/onFrame";

  export let balance: number = 0;
  export let symbol = false;
  export let big = false;
  export let disableFrame = false;
  export let f: number = 2;

  let current = balance;

  $: sym = symbol ? (current < 0 ? "-" : "+") : "";
  $: show = big ? formatBig(current, f) : format(current, f);

  onFrame((dtime) => {
    if (disableFrame) {
      current = balance
    } else {
      var delta = current - balance;
      if (Math.abs(delta) > 2) {
        current -= delta * dtime * 0.01;
      } else {
        current -= delta;
      }
    }
  });
</script>

<slot {show} {current} {sym} positive={current > 0} negative={current < 0}>
  {sym + show}
</slot>
