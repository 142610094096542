<script lang="ts">
  import Flex from "../../components/Flex.svelte";
  import Header from "./components/Header.svelte";
  import Form from "./components/Form.svelte";
  import Accepted from "./components/Accepted.svelte";
  import {profile} from "../../store/profile";

  $: isAccepted = $profile.whitelistBought
</script>


<div class="root">
    <Header />
    {#if isAccepted}
        <Accepted />
    {:else}
        <Form />
    {/if}
</div>

<style>
    .root {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;
    }
</style>